import Icon from "./2.png";
import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import LogoModificationInstructions from "./LogoModificationInstructions";
import LogoModificationSucess from "./LogoModificationSuccess";

class LogoModification extends Component {
  state = {
    selectedFile: null,
    fileName: null,
    fileUploadedSuccessfully: false,
    brandKey: null,
    brandName: null,
    brandKeyInputted: false,
    selectOptions: [],
    id: "",
  isLoading: false};

  componentDidMount() {
    this.getOptions();
  }

  async getOptions() {
    const res = await axios.get(
      "https://gbu0e4dkpi.execute-api.us-west-2.amazonaws.com/brandKeys"
    );
    const data = res.data;

    const options = data.map((d) => ({
      value: d.brand_key,
      label: d.brand_name,
    }));
    this.setState({ selectOptions: options });
    
  }

  handleChange(e) {
    this.setState({ brandKey: e.value, brandName: e.label });
  }
  
  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  onTextChange = (event) => {
    this.setState({ brandKey: event.target.value });
  };

  onFileUpload = () => {
    const formData = new FormData();
    formData.append(
      "demo file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    var upload = document.getElementById("upload");
    var file = upload.files[0];

    axios
      .post(
        "https://gbu0e4dkpi.execute-api.us-west-2.amazonaws.com/uploadToS3",
        file
      )
      .then(() => {
        this.setState({ fileName: this.state.selectedFile.name });
        this.setState({ selectedFile: null });
        this.setState({ fileUploadedSuccessfully: true });
      });
  };

  onTextSubmit = () => {
    
    this.setState({isLoading: true})

    const brandNameVar = this.state.brandName
    const fileNameVar = this.state.fileName

    const confirmBox = window.confirm(`\n \n Please double check what you are submitting! \n \n You are updating ${brandNameVar.toUpperCase()} with the \n this file: ${fileNameVar.toUpperCase()} \n \n Please cancel this submission if you feel the need to restart the process from the beginning.`)
    if (confirmBox === true) {    const formData = new FormData();
      formData.append("demo file", this.state.brandKey);
  
      var brandKeyInput = this.state.brandKey;

  
      axios
        .post(
          "https://gbu0e4dkpi.execute-api.us-west-2.amazonaws.com/updateLogoUrl",
          brandKeyInput
        )
        .then(() => {
          this.setState({ brandKey: null });
          this.setState({ brandKeyInputted: true });
          this.setState({isLoading: false})
        });}

  };

  inputText = () => {
    if (this.state.isLoading) {
      return (<div className="loading"></div>)
    }

    if (this.state.brandKeyInputted) {
      return (
        <div><LogoModificationSucess /></div>
      );
    }
  };

  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>
          <p>
            Last Modified: {""}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else if (this.state.fileUploadedSuccessfully) {
      return (
        <div>
          <br />
          <h4>Your file has been successfully uploaded to S3</h4>
          <img src={Icon} width="100px" />
          <div>
            <h3>
              Please input the brand key of the brand you are updating the logo:
            </h3>
            <div>
              {this.inputText()}
              <br />
              <Select
                className="search-box"
                options={this.state.selectOptions}
                onChange={this.handleChange.bind(this)}
              />
              <br />
            </div>
            <button onClick={this.onTextSubmit}>
              Submit</button>
          </div>
          <br />
          <br />
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose a file and select upload</h4>
          <br />
          <br />
        </div>
      );
    }
  };

  render() {
    return (
      <div className="container">
        <LogoModificationInstructions />
        <div className="form">
          <h3>Please Upload File Logo:</h3>
          <div>
            <input type="file" id="upload" onChange={this.onFileChange} />
            <button onClick={this.onFileUpload}>Upload</button>
          </div>
        </div>
        {this.fileData()}
      </div>
    );
  }
}
export default LogoModification;
