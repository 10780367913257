function LogoModificationSucess() {
    return (
        <div>
          <br />
          <h2 className="confirmation">Your submission was successful!</h2>
          <br />
        </div>
    )
}

export default LogoModificationSucess