function LogoModificationInstructions() {
  return (
    <div>
      <h1>Brand Logo Modification</h1>
      <p>
        Upload an image to S3 storage and update the brand details with the new
        logo's S3 location.
      </p>
      <h4>Instructions</h4>
      <div className="instructions">
        <p>
          {" "}
          <b>1.</b> Save the PNG image you have to "brandname".png
        </p>
        <p>
          {" "}
          <b>2.</b> Upload the PNG image using the upload form below
        </p>
        <p>
          {" "}
          <b>3.</b> Once the file has uploaded succesfully, use the {" "}
          <br /> dropdown to select the brand you want to attach the uploaded logo to
        </p>
        <p>
          {" "}
          <b>4.</b> Wait for for the 'Submitted' confirmation which confirms{" "}
          the update was successful
        </p>
        <p>
          {" "}
          <b>5.</b> If you wish to update another brand's logo, refresh the page.{" "}
          Otherwise, exit the application. Please refrain from keeping the {" "}
          window open when not in use
        </p>
      </div>
      <br />
      <br />
    </div>
  );
}

export default LogoModificationInstructions;
