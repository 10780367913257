import "./App.css";
import React, { Component } from "react";
import CetoBanner from "./components/CetoBanner/CetoBanner";
import LogoModification from "./components/LogoModification/LogoModification";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import HomeButton from "./components/CetoBanner/HomeButton";

function App() {
  return (
    <div className="container">
      <CetoBanner />
      <BrowserRouter>
        <HomeButton />
        <Routes>
          <Route path="/">
            <Route path="LogoModification" element={<LogoModification />} />
            <Route path="Home" element={<HomePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
