import { useNavigate } from "react-router-dom";


function HomePage() {

    const nav = useNavigate();

  function LogoModificationHandler() {
    nav("/LogoModification");
  }
    return (
    <div>
      <br />
      <br />
      <h2>Welcome to CETO! Please select a feature below to get started:
      <br />
      <br />
      <br />
      <button className="feature-link" onClick={LogoModificationHandler}>Brand Logo Modification</button>
      <button className="feature-link" >Place Holder 1</button>
      <button className="feature-link" >Place Holder 2</button>
      <br />
      <br />
      <button className="feature-link" >Place Holder 3</button>
      <button className="feature-link" >Place Holder 4</button>
      <button className="feature-link" >Place Holder 5</button>
    </h2>
    </div>
  );
}

export default HomePage;
