import Logo from "./1.png";
import HomeButton from "./HomeButton";


function CetoBanner() {
  
  return (
    <div className="banner">
      <img src={Logo} width="100px" />
      <br />
    </div>
  );
}

export default CetoBanner;
