import { useNavigate } from "react-router-dom";

function HomeButton() {
  const nav = useNavigate();

  function handleClick() {
    nav("/home");
  }

  return (
    <div>
    <br />
    <button type="button" className="home-button" onClick={handleClick}>
      Go to Home Page
    </button></div>
  );
}

export default HomeButton